<template>
  <div class="mt-3 cr-flow-title-wrapper">
    <div class="grey--text text--darken-2 font-weight-black">
      프로젝트 이름*
    </div>

    <div class="d-flex align-center">
      <AvatarMenu :avatar.sync="avatar" />
      <TextField
        ref="text"
        :title.sync="title"
        v-on="$listeners"
        @blur="$refs.tooltip.close()"
      />
    </div>

    <Tooltip ref="tooltip" />
  </div>
</template>

<style lang="scss" scoped>
.cr-flow-title-wrapper::v-deep {
  position: relative;
  .cr-avatar-activator {
    margin-left: 6px;
  }
}
</style>

<script>
import { isBlank } from "@/commons/utils/validation";
import AvatarMenu from "@/flow/views/components/commons/avatarMenu";
import TextField from "./TextField.vue";
import Tooltip from "./Tooltip.vue";

export default {
  components: { AvatarMenu, TextField, Tooltip },
  data({ $store: { state } }) {
    const { project } = state?.flowDialog?.params || {};
    return {
      title: project?.title || "",
      avatar: {
        size: 34,
        color: "#b71c1c",
        title: project?.title?.slice?.(0, 2) || "",
        emoji: project?.emoji || ""
      }
    };
  },
  watch: {
    title(title) {
      this.avatar.title = title;
    }
  },
  methods: {
    getData() {
      if (isBlank(this.title)) {
        this.$refs.text.$refs.text.focus();
        throw new Error("프로젝트 이름은 필수 입력 항목입니다.");
      }

      const { color, emoji } = this.avatar;
      return { title: this.title, color, emoji };
    }
  }
};
</script>
